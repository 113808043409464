@import "/public/assets/style/variables";

.withdraw-form {
  margin: 0 auto;
 

  .wired-open {
    overflow: hidden;
    animation: wired 0.2s linear forwards;
  }

  :global .ant-form-item {
    height: 87px !important;
    margin-bottom: 2px;
  }

  .email-input {
    height: 107px !important;
    margin-bottom: 2px;
  }

  @keyframes wired {
    0% {
      height: 0;
    }

    100% {
      height: 540px;
    }
  }
}

.withdraw-button {
  @include button();
}

input[type="number"] {
  padding-right: 8px;
  border: 1px solid #d9d9d9;
  outline: none;
  line-height: 1.5715;
  cursor: pointer;
  padding: 4px 0 4px 10px;

  &:focus {
    border: 1px solid rgba(12, 96, 114, 0.91) !important;
    box-shadow: 0 0 2.5px rgba(12, 96, 114, 1);
  }
}
