@import "/public/assets/style/variables";

.ref-link {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

//:global .ant-modal-confirm-body>.anticon {
//  float: right;
//  margin-right: 16px;
//  font-size: 22px;
//}

