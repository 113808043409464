body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    /*box-sizing: border-box;*/
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.full-screen-loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999;

    backdrop-filter: blur(15px);
    background: rgba(0, 0, 0, 0.929);
    background-color: hsla(0, 0%, 0%, 0.936);
    display: flex;
    justify-content: center;
    padding: 30px;
}
