@import "/public/assets/style/variables";

  .profile-input-block {
    
    .item {
      .select {
        width: 265px !important;
      }
      width: 265px !important;
    }
    Input {
      width: 265px !important;
    }

    .drawer-open {
      position: absolute;
      top: 0;
      left: 50px;
      cursor: pointer;
      color: $link-color;
      z-index: 100;
    }
  }

.form-input-block{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

}

.drawer-button{
  width: 200px;
  margin: auto;
  display: block;
}

:global .ant-drawer-header-title {
  height: 50px !important;
}

@media screen and (max-width: 600px) {
  .form-input-block {
    grid-template-columns: 1fr;
  }
}
