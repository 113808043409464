@import "/public/assets/style/variables";

.button {
  @include button();
  //@include display-center(flex, center, center);
  //.for-item-buttons {
  //  width: 220px;
  //  @include display-center(flex, space-between, center);
  //  Button {
  //    width: 100px;
  //  }
  //}
}
