@import "/public/assets/style/variables";

.current-device-button {
  @include button();
}
.packages-select{
  width: 100% !important;
}

.protected{
  margin-bottom: 18px;
}
