@import "/public/assets/style/variables";

.buy-more-drawer {
    .title {
        font-size: 22px;
    }
    .buy-more-form {
        margin: 0 auto;
        .item {
            .select {
                width: 552px !important;
            }
            width: 552px !important;
        }

        .checkbox-block {
            width: 115px;
            margin-top: 20px;
            margin-bottom: 20px;
            @include display-center(flex, space-between, center);
            a {
                color: $link-color;
            }
            a:hover {
                color: $link-hover-color;
            }
        }

        .paypal-loading {
            padding: 0 0 20px 0;
            @include display-center(flex, center, center);
        }

        .paypal-open {
            overflow: hidden;
            animation: paypal 0.2s linear forwards;
        }

        .paypal-close {
            overflow: hidden;
            animation: p-close 0.2s linear forwards;
        }

        @keyframes paypal {
            0% {
                height: 0;
            }

            100% {
                min-height: 185px;
            }
        }

        @keyframes p-close {
            0% {
                height: 185px;
            }

            100% {
                height: 0;
            }
        }

        .custom-open {
            overflow: hidden;
            height: 115px;
            transition: height 0.2s;
            margin-bottom: 20px;
        }

        .custom-close {
            transition: height 0.2s;
            overflow: hidden;
            height: 0;
        }

        .custom-transfer {
            width: 552px;
            height: 115px;
            background-color: #e9e9e9;
            border-radius: 5px;
            @include display-center(flex, center, center);
            .custom-transfer-container {
                @include display-flex-direction(flex, column, center);
                .activations-link-block {
                    width: 200px;
                    height: 35px;
                    border-radius: 3px;
                    @include display-center(flex, center, center);
                    background-color: $primary-dark;

                    .activations-link-icon {
                        font-size: 17px;
                        color: $color;
                    }
                    a {
                        margin-left: 5px;
                        margin-bottom: 3px;
                        display: inline-block;
                        color: $color;
                        font-size: 15px;
                    }
                }
                .custom-transfer-text {
                    width: 490px;
                    text-align: center;
                    padding-bottom: 10px;
                    font-size: 12px;
                }
            }
        }
    }
}
