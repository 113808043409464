@import "/public/assets/style/variables";

.login-page {
  @include display-center(flex, space-between, center);
  width: 100%;
  height: 100vh;
  @include background();
  .login-page-illustration-container {
    @include display-center(flex, center, center);
    width: 50%;
    img {
      width: 550px;
    }
  }
  .login-form-block {
    @include display-center(flex, center, center);
    width: 50%;
    .login-form-block-container {
      box-shadow: 3px 3px 11px 4px #b5a7a163;
      padding: 50px 0 28px 0;
      border-radius: $border-radius;
      background: $secondary-background;
      @include display-flex-direction(flex, column, center);
      width: 450px;
      min-width: 450px;
    }
    img {
      width: 70px;
      padding: 0 0 30px 0;
    }
    .login-page-form {
      width: 80%;
    }
  }

  @media (max-width: 992px) {
    .login-page-illustration-container {
      display: none;
    }
  }

  @media (max-width: 576px) {
    .login-form-block {
      .login-form-block-container {
        width: 310px;
        min-width: 310px;
      }
    }
  }
}

@media (max-width: 992px) {
  .login-page {
    @include display-center(flex, center, center);
  }
}



