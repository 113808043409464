@import "/public/assets/style/variables";

:global .ant-form {
    height: auto;
}

Form {
    Button {
        width: 100%;
        margin: 0 auto;
    }

    .form-item {
        text-align: center;
        
        a {
            color: #1890ff;
            // color: $link-color;
        }
        a:hover {
            color: #52a3ef;
            // color: $link-hover-color;
        }
    }

    .form-input-block {
        :global .ant-form-item {
            height: 62px!important;
            margin-bottom: 2px;
        }
    }
}



.login-form_google-recaptcha {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }