@import "/public/assets/style/variables";

.menu {
  width: 200px;
  top: 13px;
  :global .ant-dropdown-menu-title-content {
    @include display-center(flex, space-between, center);
  }
  .wallet-dropdown {
    text-align: start;
    cursor: auto;
    .total-number {
      font-weight: 700;
    }
  }
}

.wallet-img {
  width: 20px;
  cursor: pointer;
}
